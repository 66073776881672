<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card
      class="mt-0"
      style="height: 96vh"
    >
      <iframe
        :src="$store.state.url.replace('api/', '') + 'phonebook'"
        frameborder="0"
        style="height: 100%; width: 100%"
      />
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        itemWikiS: '',

      }
    },

    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },

    methods: {
      searchwiki () {
        window.location.href = 'http://it/projects/sysadmin/search?utf8=✓&wiki_pages=1&q=' + this.itemWikiS
        console.log('sss')
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
